import React, {useState, useEffect, useRef} from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent  } from '@syncfusion/ej2-react-calendars';
import { useHistory } from "react-router-dom";
import moment from "moment";
import ArrowPrevIcon from "../../images/arrow-left-back.svg";
import api from "../../lib/API";
import CreateLogo from "../../images/Create.svg";
import localStorageService from "../../lib/LocalStorageService";
import "./style.css";

export default function AnalysisHistory ({ visibility, setVisibility, jobID, setJobID, setPrevJobID, historyList, Footer }) {
    const permission = localStorageService.getPermissions();
    const [processedDateFrom, setProcessedDateFrom] = useState(null);
    const [processedDateTo, setProcessedDateTo] = useState(null);
    const [title, setTitle] = useState(null);
    const titleRef = useRef(null);
    const dt = useRef(null);
    var history = useHistory();

    useEffect(()=>{
        let mounted = true;
        if(mounted){
            fetchUpdate();
        }
        return () => mounted = false;
    },[]);

    const fetchUpdate = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const queryJobID = parseInt(queryParams.get("jobID"), 10) || jobID;
        if (jobID != queryJobID){
            setJobID(queryJobID);
            setPrevJobID(jobID);
            setVisibility(false);
        }
    }

    const updateHistoryList = () => {
        let filteredList = [...historyList];
        if (title) {
            filteredList = filteredList.filter(job => 
                job.title && job.title.toLowerCase().includes(title.toLowerCase())
            );
        }
        if (processedDateFrom) {
            filteredList = filteredList.filter(job =>
                job.processedTime && moment(job.processedTime).isSameOrAfter(processedDateFrom, 'day')
            );
        }
        if (processedDateTo) {
            filteredList = filteredList.filter(job =>
                job.processedTime && moment(job.processedTime).isSameOrBefore(processedDateTo, 'day')
            );
        }
        dt.current.dataSource = filteredList;
    };
    
    const handleImageClick = () => {
        setVisibility(false);
    }

    const rowSelected = () => {
        const selectedrecords = dt.current.getSelectedRecords();
        if (selectedrecords.length > 0) {
            let obj = selectedrecords[0];
            setJobID(obj.jobID);
            setPrevJobID(jobID);
            setVisibility(false);
            history.push({
                pathname: "/",
                search: `?jobID=${obj.jobID}`
            });
        }
    }

    const jobIDTemplate = (row) => {
        return (
            row.jobID ? row.jobID : "-"
        );
    }

    const titleTemplate = (row) => {
        return (
            row.title ? row.title : "-"
        );
    }

    const keySDGsTemplate = (row) => {
        return (
            <div style={{ display: "flex", gap: "2px" }}>
                {row.keySDGs ? (
                    row.keySDGs.split(',').map((sdg_number, idx) => (
                        <div
                            key={`job-list-${row.jobID}-${idx}`}
                            className={`goal-icon goal-${sdg_number.trim()} sdg-list-box`}
                            style={{ fontWeight: "500" }}
                        >
                            {sdg_number.trim()}
                        </div>
                    ))
                ) : (
                    <div style={{ fontWeight: "500" }}>-</div>
                )}
            </div>
        );
    }

    const processedTimeTemplate = (row) => {
        return (
            row.processedTime ? moment(row.processedTime).format("YYYY-MM-DD HH:mm") : "-"
        );
    }

    return (
        <>
            <div className="container-wrapper">
                <div className="main-content">
                    <div style={{ margin: "26px 32px" }}>
                        <div>
                            <div style={{fontSize: "22px", fontWeight: "bold", marginBottom: "15px", color: "#2B3443"}}>SDG Classification and Keyword Recommendation</div>
                            <div style={{fontSize: "15px", fontWeight: "400", color: "#707680"}}>
                                The software system aims to improve sustainable development and research excellence by facilitating the identification of Sustainable Development Goals (SDG)-related keywords in academic publications. By streamlining the categorization of research outputs, the system enhances the visibility and impact of academic work aligned with the UN’s sustainability agenda. This initiative will serve as a vital tool for researchers, enabling them to connect their work with relevant global goals more effectively, fostering interdisciplinary collaboration, and improving access to sustainability research for stakeholders such as policymakers and industry leaders. Ultimately, the system reflects a commitment to creating an academic environment that prioritizes sustainability and contributes to a more sustainable future for all.
                            </div>
                        </div>
                        <div style={{ margin: "15px 0px 15px" }}>
                            <button
                                className="btn btn-dark"
                                
                                onClick={() => {
                                    setJobID(-1);
                                    setVisibility(false);
                                }}
                            >
                                <img src={CreateLogo} alt="" />{` `} New Analysis
                            </button>
                            
                            <span className="separator"></span>

                            <span>
                                <DatePickerComponent id="processedDateFrom" value={processedDateFrom} placeholder="From Processed Date" floatLabelType="Never" cssClass="filter-box" width="200px"
                                format="yyyy-MM-dd"
                                change={(e)=>{
                                    if (e.value) {
                                        setProcessedDateFrom(moment(e.value).format('YYYY-MM-DD'));
                                    } else {
                                        setProcessedDateFrom('');
                                    }
                                }}
                                cleared={()=>{
                                    setProcessedDateFrom('');
                                }}
                                />
                            </span>

                            <span style={{ paddingLeft: "10px" }}>
                                to
                            </span>

                            <span style={{ paddingLeft: "10px" }}>
                                <DatePickerComponent id="processedDateTo" value={processedDateTo} placeholder="To Processed Date" floatLabelType="Never" cssClass="filter-box" width="200px"
                                format="yyyy-MM-dd"
                                change={(e)=>{
                                    if (e.value) {
                                        setProcessedDateTo(moment(e.value).format('YYYY-MM-DD'));
                                    } else {
                                        setProcessedDateTo('');
                                    }
                                }}
                                cleared={()=>{
                                    setProcessedDateTo('');
                                }}
                                />
                            </span>

                            <span style={{ paddingLeft: "15px" }}>
                                <TextBoxComponent id="titleText" ref={titleRef} value={title} input={e=>setTitle(e.value)} change={e=>setTitle(e.value)} cssClass="filter-box" width="274px"
                                placeholder="Title" floatLabelType="Never" autocomplete={'off'}/>
                            </span>

                            <span style={{ paddingLeft: "15px" }}>
                                <button
                                    className="btn btn-dark pl-3 pr-3"
                                    onClick={()=>{
                                        if (processedDateFrom && processedDateTo && processedDateFrom > processedDateTo) {
                                            alert("'From Processed Time' should be earlier than 'To Processed Time'.");
                                        }
                                        else{
                                            updateHistoryList();
                                        }
                                    }}>
                                    Filter
                                </button>
                            </span>
                        </div>
                        <div className="history-table">
                            <GridComponent
                                height="calc(100vh - 500px)"
                                rowSelected={rowSelected}
                                allowPaging={true}
                                allowTextWrap={true}
                                allowSorting={false}
                                ref={dt}
                                queryString={true}
                                dataSource={historyList}
                                rowHeight={50}
                                pageSettings={{ enableQueryString: true, pageSize: 50, pageCount: 5 }}
                                rowDataBound={(args) => {
                                    args.row.setAttribute("style", "cursor: pointer;");
                                    args.row.classList.add("row-hover");
                                }}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective headerTextAlign="left" textAlign="left" width="40px" field='jobID' headerText='Job ID' template={jobIDTemplate}></ColumnDirective>
                                    <ColumnDirective headerTextAlign="left" textAlign="left" width="600px" field='title' headerText='Title' template={titleTemplate}></ColumnDirective>
                                    <ColumnDirective headerTextAlign="left" textAlign="left" width="80px" field='keySDGs' headerText='Key SDGs' template={keySDGsTemplate}></ColumnDirective>
                                    <ColumnDirective headerTextAlign="left" textAlign="left" width="80px" field='processedTime' headerText='Processed Time' template={processedTimeTemplate}></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Page]} />

                            </GridComponent>
                        </div>
                    </div>
                    <Footer />
                </div>
                
            </div>
        </>
    );
} 