import React, {memo, forwardRef, useImperativeHandle, useState, useEffect, useRef} from "react";
import "./styles.css";

import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Sort } from '@syncfusion/ej2-react-grids';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useHistory } from "react-router-dom";
import LinkIcon from "../../images/Link-icon.svg";

import Warning from "../../images/Warning.svg";
import Download from "../../images/Download.svg"
import Delete from "../../images/Delete.svg"

import moment from "moment";
import api from "../../lib/API";
import localStorageService from "../../lib/LocalStorageService";

const JobQueueTable = forwardRef((props, ref) => {

    // const [page, setPage] = useState({pageSize: 15});
    const gridRef = useRef(null);
    const permission = localStorageService.getPermissions();
    const [data, setData] = useState({});
    var page = {pageSize: 50};
    var history = useHistory();
    var isRefreshing = false;

    function fetchAllJobs(auto=false){
        if (!auto || !isRefreshing) {
            // console.log("refreshing");
            isRefreshing = true;
            api.get(`/jobs`).then(async (res) => {
                setData(res.data.jobList);
                // console.log("HERE!", res.data.jobList);
                isRefreshing = false;
            }, err=>{
                isRefreshing = false;
            })
        } else {
            console.log("Skipped auto fetchJobs");
        }
    }

    // auto-refresh after 10 second
    useEffect(()=>{
        const id = setInterval(() => {fetchAllJobs(true);}, (process.env.REACT_APP_AUTO_REFRESH || 10) * 1000);
        return () => clearInterval(id);
    },[])

    const jobIdTemplate = (props) => {
        return <span className="content-text">{props.jobId}</span>
    }

    const titleTemplate = (props) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {props.status === "Suggesting Keywords" && (
                    <img src={LinkIcon} style={{ marginLeft: "10px" }} alt="Link Icon" />
                )}
                <span className="content-text" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    {props.title}
                </span>
            </div>
        );
    };
    

    const statusTemplate = (props) =>{
        //props = the whole object of each row
        //console.log(props);
        let status = props.status;
        switch (status) {
            case "Pending":
                return (
                    <span style={{backgroundColor:"#ededf2"}} className="status-badge p-2 pl-3 pr-3">
                        <span className="text-secondary">Pending</span>
                    </span>);
            case "In Queue":
                return (
                    <span style={{backgroundColor:"#e5eeff"}} className="status-badge p-2 pl-3 pr-3">
                        <span className="text-primary">In Queue</span>
                    </span>);
            case "In Progress":
            case "Suggesting Keywords":
                return (
                    <span style={{backgroundColor:"#f6f3e0"}} className="status-badge p-2 pl-3 pr-3">
                        <span className="text-warning">In Progress</span>
                    </span>);
            case "Completed":
                return (
                <span style={{backgroundColor:"#daf5db"}} className="status-badge p-2 pl-3 pr-3">
                    <span className="text-success">Completed</span>
                </span>);
            default:
                return null
        }
    };

    const uploadedTimeTemplate = (props) => {
        return <span className="content-text">{moment(props.uploadedTime).format("YYYY-MM-DD HH:mm")}</span>
    }

    const queuePositionTemplate = (props) => {
        return <span className="content-text">{props.queuePosition !== null ? props.queuePosition : "-"}</span>
    }

    const progressTemplate = (props) => {
        if (!props || !props.jobId || props.status === "In Queue") return null;
        return (
            <span>
                <progress max={100} value={props.progress} />
                <span style={{marginLeft: "5px"}}>{props.progress}% - {props.remarks}</span>
            </span>
        )
    };

    const rowSelected = () => {
        const selectedrecords = gridRef.current.getSelectedRecords();
        if (selectedrecords.length > 0) {
            let obj = selectedrecords[0];
            history.push({
                pathname: "/",
                search: `?jobID=${obj.jobId}`
            });
        }
    }
    
    return (
        <div className="job-queue-table">
            <GridComponent
                ref={gridRef}
                height= "calc(100vh - 310px)"
                dataSource={data} 
                allowPaging={true}
                rowHeight={50}
                pageSettings={page} 
                sortSettings={{columns:[{field:'jobId',direction:'Descending'}]}}
                created={fetchAllJobs}
                rowSelected={rowSelected}
                rowDataBound={(args) => {
                    const rowData = args.data;
                    if (rowData.status !== "Suggesting Keywords") {
                        args.row.setAttribute("style", "cursor: not-allowed; pointer-events: none;");
                    }
                    else {
                        args.row.setAttribute("style", "cursor: pointer;");
                        args.row.classList.add("row-hover");
                    }
                }}
            >
                <ColumnsDirective>
                    <ColumnDirective headerTextAlign="center" textAlign="center" field="jobId" width="50px" headerText='Job ID' template={jobIdTemplate} />
                    <ColumnDirective headerTextAlign="left" textAlign="left" field="title" width="300px"headerText='Title' template={titleTemplate} />
                    <ColumnDirective headerTextAlign="left" textAlign="left" field="uploadedTime" width="100px" headerText='Uploaded Time' template={uploadedTimeTemplate} />
                    <ColumnDirective headerTextAlign="left" textAlign="left" field="queuePosition" headerText='Queue Position' width="80px"  template={queuePositionTemplate} />
                    <ColumnDirective headerTextAlign="left" textAlign="left" field="status" headerText='Status' width="80px"  template={statusTemplate} />
                    <ColumnDirective headerTextAlign="left" textAlign="left" field="progress" headerText='Progress' width="220px"  template={progressTemplate} />
                </ColumnsDirective>
                <Inject services={[Page,Sort]}/>
            </GridComponent>
        </div>
    )
});

export default memo(JobQueueTable);